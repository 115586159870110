import React, { useEffect, useState, useRef } from "react";
import styles from "./UploadScreen.module.scss";
import Layout from "../components/ui/Layout";
import { useNavigate, useLocation } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import { SlDoc } from "react-icons/sl";
import {ImBin2} from "react-icons/im";
import Loader from "../components/ui/Loader";
import CameraScreen from "./CameraScreen";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "./state/upload/actions";
import { Endpoints } from "../core/networking";
import { debounce } from "lodash";
import { Scrollbars } from "react-custom-scrollbars-2";
import TaskDescription from "./components/TaskDescription";
import PageTitle from "./components/PageTitle";
import { Link } from 'react-router-dom';


dayjs.extend(customParseFormat);
const UploadScreen = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        loginData: { loginUser },
        uploadData: { uploadFileTask, fileDownload },
    } = useSelector((state) => state);
    const { response: loginVal } = loginUser || {};
    const { response: uploadFileResponse, isLoading: loading, error: fileUploadError } = uploadFileTask || {};
    const { response: downloadedFile, isLoading: fileLoading } =
        fileDownload || {};
    
    const photofileref = useRef();
    
    const [, setMsgInput] = useState("");
    const [, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState([]);
    const [selectedPhotoFile, setSelectedPhotoFile] = useState();
    
    const [, setPhotoIsFilePicked] = useState(false);
    const [showUploadOptions, setShowUploadOptions] = useState(false);
    const [pageTitle, setPageTitle] = useState("");
    const [, setPageMsg] = useState(""); //pageMsg
    const [showWebCamPhoto, setShowWebCamPhoto] = useState(false);
    
    const [fileName] = useState();
    const [scrollbarHeight,setScrollBarHeight] = useState("");
    const [isFileUploadDone, setIsFileUploadDone] = useState(false);
    const [isFileDeleted, setIsFileDeleted] = useState(false);
    
    const [validateFileSize,setValidateFileSize] = useState(true);
    const [showFileErrorModel, setShowFileErrorModel] = useState(false);
    const [showFileQtyErrorModel, setShowFileQtyErrorModel] = useState(false);
    const [errorUploadMsg, setErrorUploadMsg] = useState("");
    const fileLimit = 60;
    const [is403, setIs403] = useState(false);

    useEffect(() => {
        document.title = "Upload File";

		dispatch(Actions.fileDownloadClear());
        dispatch(Actions.portalFileClear());

        setSelectedFile([]);
        setShowFileErrorModel(false);
        setIsFileUploadDone(false);
        setIsFileDeleted(false);
        setShowFileQtyErrorModel(false);
        setErrorUploadMsg("");

        dispatch(Actions.portalFileClear());

        if (location.state != null) {
           
            if (location.state.page != null)
                localStorage.setItem(
                    "uploadScreen",
                    JSON.stringify(location.state)
                );
            if (location.state.id != null) {
                const taskEndPoint = Endpoints.UPLOAD_GET_MSG_FOR_TASK + location?.state?.id;
                // eslint-disable-next-line
                let params = { endpoint: taskEndPoint, method: "GET" };
            }
        }


        if(location?.state?.taskInfo)
        setPageTitle(location.state.taskname === '' ? location?.state?.taskInfo?.TaskName: location.state.taskname);

        if (location?.state?.taskInfo?.IsRejected)
            setPageMsg(location?.state?.taskInfo?.RejectReason);
        else
            setPageMsg(location?.state?.taskInfo?.Comment);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (fileUploadError) {
            setIs403(fileUploadError?.response?.status === 403); // set the status of file upload  
            setErrorUploadMsg(fileUploadError?.message);
            dispatch(Actions.portalFileClear());
        }

    }, [fileUploadError]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (typeof uploadFileResponse !== "undefined" && uploadFileResponse !== null && uploadFileResponse) {
            if(uploadFileResponse?.status === 200)
            {
                setIsFileUploadDone(true);
                const timeout = setTimeout(() => {
                    setIsFileUploadDone(false);
                    setMsgInput("");
                    setSelectedFile([]);
                    navigate(-1);
                }, 3000);
                return () => clearTimeout(timeout)
            }
            dispatch(Actions.portalFileClear());
        }else {
            setIsFileUploadDone(false);
        }
    }, [uploadFileResponse, dispatch]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        
        if (
            typeof downloadedFile !== "undefined" &&
            downloadedFile !== null &&
            downloadedFile
        ) {
            const linkSource = downloadedFile;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        }
        // eslint-disable-next-line
    }, [downloadedFile]);

    useEffect(()=>{
        if(!validateFileSize)
		    setShowFileErrorModel(true);
	},[validateFileSize]);

    const getContentHeight = () => {
        const mainElement = document.getElementsByClassName("app");
        const pageTitle = document.querySelector('.pageTitle');
        const footerButton = document.querySelector('.footerButton');

        // 20 - bottom spacing of footer button, 5 - additional space
        return mainElement[0]?.offsetHeight - (pageTitle?.clientHeight + footerButton?.clientHeight + 25);
    }

    const closeFileErrorUpload = () => {
        
        if (is403 === true) { // if the error is 403, then logout the app.
            navigate("/logout", { replace: true });
        }
        setErrorUploadMsg("")
    }

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setScrollBarHeight(getContentHeight());
            console.log(getContentHeight());
        }, 300);

        // set scroll window height
		setScrollBarHeight(getContentHeight());
		window.addEventListener("resize", debouncedHandleResize);

        // cleanup listener
        return _ => {
            window.removeEventListener('resize', debouncedHandleResize);
        }
	}, [scrollbarHeight]);

    const goBack = () => {
        navigate(-1);
    };

    function appendFiles(files)
    {
        for(let i =0;i<files.length;i++)
        {
           setSelectedFile(file => [...file,files[i]]);
        }
    }

	useEffect(()=>{
        let totalFileSize = 0;
        setValidateFileSize(true);
        for(let i = 0;i<selectedFile.length;i++)
        {
            let fileSize = selectedFile[i]?.size / 1024 / 1024;
            totalFileSize = totalFileSize + Math.ceil(fileSize);
        }
        if (totalFileSize > fileLimit)
        {
            setValidateFileSize(false);
        }
	},[selectedFile])

    const removeAttachment =(fileindex) => {
        setIsFileDeleted(true);
        setSelectedFile([
            ...selectedFile.slice(0, fileindex),
            ...selectedFile.slice(fileindex + 1)
        ]);
        const timeout = setTimeout(() => {
            setIsFileDeleted(false);
        }, 3000);
        return () => clearTimeout(timeout)
    }


    const photoChangeHandler = (event) => {
        setSelectedPhotoFile("");
        appendFiles(event.target.files);
        setPhotoIsFilePicked(true);
        setShowUploadOptions(false);

        location.state.camerapic = "";
    };


    const handleSubmission = () => {
        if (selectedFile.length < location?.state?.taskInfo?.minUpload)
        {
            setShowFileQtyErrorModel(true);
        }else{
            const formData = new FormData();
            setIsLoading(true);
            formData.append("ApplicantID", loginVal?.data?.applicant_id);
            formData.append("taskID", location?.state?.id);
            formData.append("endpoint", "tasks/AddTaskAttachments");

            for(let i = 0; i<selectedFile.length;i++)
            {
                formData.append("Attachments[]", selectedFile[i]);
            }

            dispatch(Actions.portalUploadFileRequest(formData));
        }
    };


    const PicOpen = () => {
        photofileref.current.click();
    };
    

    function formatBytes(bytes, decimals = 2) {
        if (!+bytes) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${
            sizes[i]
        }`;
    }

    const convertImgBase64StrToFile = (base64, fname) => {
        if(base64 != null)
        {
            var arr = base64.split(",");
            var mime = arr[0].match(/:(.*?);/)[1];
            var bstr = atob(arr[1]);
            var n = bstr.length;
            var u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], fname, { type: mime });
        }
    };

    const getCameraPicture = (picSrc) => {
        let current = new Date();
        let cDate =
            current.getFullYear() +
            "-" +
            (current.getMonth() + 1) +
            "-" +
            current.getDate();
        let cTime =
            current.getHours() +
            ":" +
            current.getMinutes() +
            ":" +
            current.getSeconds();
        let dateTime = cDate + "_" + cTime;

        let pictureFilename = dateTime + ".jpg";
        setSelectedPhotoFile("");

        setSelectedFile(file => [...file,convertImgBase64StrToFile(picSrc, pictureFilename)]);
        setShowWebCamPhoto(false);
    };

    return (
		<>
            {
                (showFileQtyErrorModel) &&
                <>
                    <div className={styles.fileErrorOverlay}></div>
                    <div className={styles.fileErrorModal}>
                        <p style={{textAlign:"center"}}>Please select at least {location?.state?.taskInfo.minUpload} photos to upload.</p>
                        <button type="button" onClick={()=>{setShowFileQtyErrorModel(false)}}>OK</button>
                    </div>
                </>
            }
            {
                (showFileErrorModel) &&
                <>
                    <div className={styles.fileErrorOverlay}></div>
                    <div className={styles.fileErrorModal}>
                        <span className={styles.errorTitle}>Max file size exceeded</span>
                        <p style={{textAlign:"center"}}>Please make sure that the total size of all files does not exceed {fileLimit} MB</p>
                        <button type="button" onClick={()=>{setShowFileErrorModel(false)}}>OK</button>
                    </div>
                </>
            }
            {
                (errorUploadMsg !== "") &&
                <>
                    <div className={styles.fileErrorOverlay}></div>
                    <div className={styles.fileErrorModal}>
                        <span className={styles.errorTitle}>Error on File upload</span>
                        <p style={{textAlign:"center"}}>{errorUploadMsg}</p>
                        <button type="button" onClick={() => { closeFileErrorUpload()}}>OK</button>
                    </div>
                </>
            }
            {
                (isFileDeleted) &&
                <div className={`d-flex align-items-center justify-content-center ${styles.pageOverlay}`}>
                    <span style={{color:"#fff",fontSize:"20px"}}>File Deleted!</span>
                </div>
            }
            {
                (isFileUploadDone) &&
                <div className={`d-flex align-items-center justify-content-center ${styles.pageOverlay}`}>
                    <span style={{color:"#fff",fontSize:"20px"}}>File Successfully Uploaded!</span>
                </div>
            }
            {(fileLoading || loading) && <Loader />}
			<Layout showHeader="false" showFooter="false" classes="pb-0">
				
                <PageTitle
                    title={pageTitle}
                    headerClass="pageTitle"
                    onClose={goBack}
                />

                <Scrollbars
                    className={`d-flex flex-column scroll-container`}
                    style={{ height: scrollbarHeight }}
                    autoHeight
                    autoHeightMax={scrollbarHeight}
                >
				
                    <TaskDescription styles={`px-3 py-3 my-2`} taskDetails={location?.state?.taskInfo} />
				{
					(location.state?.camerapic || selectedFile.length > 0) &&
                        <p style={{color:"#707070", fontSize:"18px"}} className="mt-3 mb-2 text-center">Add additional file?</p>
				}
				{showWebCamPhoto && (
					<div>
						<div
							className={styles.modalOverlay}
							onClick={() => setShowWebCamPhoto(false)}
						></div>
						<div className={styles.cameraContainer}>
							<CameraScreen onClickPicture={getCameraPicture}/>
						</div>
					</div>
				)}

				{showUploadOptions && (
					<>
						<div
							className={styles.modalOverlay}
							onClick={() => setShowUploadOptions(false)}
						></div>
						<div className={`${styles.photoModal} mt-4 mb-4 ms-0 me-0`}>
							<ul className={styles.uploadTasks}>

							</ul>
						</div>
					</>
				)}

				<div
					className={styles.chooseFileClass}
                    onClick={() => PicOpen()/*openUploadModal()*/}
				>
                    <SlDoc color="#fff" className={styles.uploadIcon} /> Choose File
                    <input
                        ref={photofileref}
                        style={{ display: "none" }}
                        accept="image/*,video/*,.doc,.docx,.pdf"
                        type="file"
                        multiple
                        name="photofile"
                        onChange={photoChangeHandler}
                        />
                    </div>

                    {
                        [5, 6].includes(location?.state?.taskInfo?.SubTaskType) &&
                        <div style={{ margin: "10px 0", textAlign: "center" }}>
                                <Link to='/photoguide' state={{ prevPage: window.location.pathname, menu: "open", fromPage: "upload", subtype: location?.state?.taskInfo?.SubTaskType }}>
                                    {
                                        location?.state?.taskInfo?.SubTaskType === 6 ?
                                            <>Egg Donor Adult Photo Guidelines</>
                                            :
                                            <>Egg Donor Photo Guidelines</>
                                    }
                                    
                                </Link>
                        </div>
                    }
                
                    <div className="messageContainer position-relative" id="messageContainer">
                    {(location.state?.camerapic !== null ||
						selectedFile.length > 0 ||
						selectedPhotoFile) && (
							<div className={`${styles.showFileContainer} text-center`}>
								{location.state?.camerapic && (
									<span>
										Camera Pic.png{" "}
										{formatBytes(location.state?.camerapic.length)}
									</span>
								)}
								<ul className={styles.uploadFileList}>
									{selectedFile && selectedFile.length > 0 && selectedFile.map((selFile,i) => {
										return (
											<li key={i}>
                                                <span className={styles.filename}>{selectedFile[i]?.name}&nbsp;{formatBytes(selectedFile[i]?.size)}</span>
												
                                                <ImBin2 onClick={()=>removeAttachment(i)} style={{fill:"#353D56",cursor:"pointer",float:"right",margin:"5px 0"}}/>
											</li>
										)
									})}
								</ul>
							</div>
						)}

				</div>

                </Scrollbars>
				<div
					style={{paddingBottom: 20}}
                    className="w-100 position-absolute bottom-0 text-center bg-white footerButton"
				>
					<div className={`${styles.inputBox}`}>
						<div
							className={`${styles.uploadButton} ${(validateFileSize === true && (location.state?.camerapic || selectedFile.length > 0))
									? styles.active
									: ""
								} `}
							style={{ marginTop: "20px" }}
						>
							<button
								className={`position-relative float-end`}
								onClick={
									(validateFileSize === true && (location.state?.camerapic || selectedFile.length > 0))
										? handleSubmission
										: undefined
								}
							>
								<span>Accept and Upload</span>
								<BsChevronRight className={styles.buttonIcon} />
							</button>
						</div>
					</div>
				</div>
			</Layout>
		</>
    );
};

export default UploadScreen;
