import { useNavigate, Link, useLocation } from "react-router-dom";
import { useState, useRef, useEffect, useContext } from 'react';
import { motion } from "framer-motion";
import {
    Form,
    Button,
} from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { CheckIsLoggedIn, Logout, getQueryParams } from '../services/Common';
import Alert from "../components/ui/Alert";
import AuthLayout from "../components/ui/AuthLayout";
import { BsChevronRight } from "react-icons/bs";
import styles from './Login.module.scss';
import Loader from "../components/ui/Loader";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "./state/login/actions";
import { APPLICANT_REJECTED_STATUS } from "../utils/constants";
import { Endpoints } from "../core/networking";
import { createBrowserHistory } from 'history';

import {
    add
} from "date-fns";


function Login(props) {
    const dispatch = useDispatch();
    const {
        loginData: {
            loginUser,
            rejectedDonor,
        },
    } = useSelector((state) => state);
    const { response: loginVal, isLoading: loading, error } = loginUser || {};
    const { response: rejectedResponse, isLoading: rejectedLoading } = rejectedDonor || {};
    const [user, setUser] = useState(props.user);
    const [rememberMe,setRememberMe] = useState("");
    const [loginFailed, setLoginFailed] = useState("");
    const [cookies, setCookie, removeCookie] = useCookies(null);
    const navigate = useNavigate();
    const emailInputRef = useRef("");
    const passwordInputRef = useRef("");
    const rememberMeRef = useRef("");
    const [isLogged, setIsLogged] = useState(false);
    const [validated, setValidated] = useState(false);
    const { state } = useLocation();
    const [emailValidated, setEmailValidated] = useState(true);
    const [pwdValidated, setPwdValidated] = useState(true);
    const [elementValidation, setElementValidation] = useState("");
    const [scrollbarHeight, setScrollBarHeight] = useState(window.screen.availHeight);
    const history = createBrowserHistory({ forceRefresh: true });

    let remembermeExpiry = 14;
    let contentExpiry = 2;

    useEffect(() => {
        document.title = 'Login';
        const params = new URLSearchParams(window.location.search);
        dispatch(Actions.forgetPasswordReset());
        dispatch(Actions.rejectStatusClear());
        dispatch(Actions.loginUserClear());
        dispatch(Actions.loginReset());

        if (CheckIsLoggedIn(cookies)) {
            if (!cookies['wp-user-mobile-login']) {
                let expiryDT = new Date(cookies['login-token-cookie']);
               
                if (!(expiryDT instanceof Date)) { // if the date returned by the cookie is invalid then default the expiry to current date.
                    expiryDT = new Date();
                }
                setCookie('wp-user-mobile-login', cookies['wp-login-user-token'], { expires: expiryDT,path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
            }            

            if(cookies['wp-login-user-token'] && (!cookies["front-end-app"] || cookies["front-end-app"] === "0")) // its considered as front end not loggedin but wp is logged in.
            {
                window.console.log("Front end not logged in");
                // So auto login the DMA
                let user = {username: cookies['wp-login-user-token'],password: "",autologin:true};
                dispatch(Actions.loginUserRequest(user));
            }
            
        } else if (cookies["wp-login-user-token"] !== undefined && cookies["wp-user-mobile-login"] !== undefined && !CheckIsLoggedIn(cookies)) {
            let userName = "";
            if(cookies["wp-login-user-token"]!==undefined) {
                userName = cookies["wp-login-user-token"];
            } else if (cookies["wp-user-mobile-login"]!==undefined) {
                userName = cookies["wp-user-mobile-login"];
            }
            let user = {username: userName,password: "",autologin:true};
            dispatch(Actions.loginUserRequest(user));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // set scroll window height
        const mainElement = document.getElementsByClassName("app");
       
        mainElement[0].style.overflowY = "auto";
      
        setScrollBarHeight(mainElement[0]?.offsetHeight);
        window.addEventListener("resize", function () {
            setScrollBarHeight(mainElement[0]?.offsetHeight);
          
        });
    }, [scrollbarHeight]);

    useEffect(() => {
        if ((loginVal?.status === 200) && loginVal?.data) {
            let applicantID = loginVal?.data?.applicant_id;
            localStorage.setItem("token", loginVal?.data?.token);
            checkRejected(applicantID);
        } else {
            loginUser.isLoading = false;
        }
        // eslint-disable-next-line
    }, [loginVal]); //dispatch

    useEffect(() => {
        if(error) {
            setLoginFailed(error?.message);
            setEmailValidated(false);
            dispatch(Actions.loginUserClear());
        }
        // eslint-disable-next-line
    }, [error]);

    useEffect(() => {
        const state = getQueryParams();
        window.console.log("---login.js----->", loginVal);
        
        if (state?.code === "403" || loginVal === null) {
            dispatch(Actions.rejectStatusClear());

            history.push({
                pathname: '/'
            });
            return;
        }
        
        if (rejectedResponse?.length === 0 || (rejectedResponse?.ID === APPLICANT_REJECTED_STATUS.ID && rejectedResponse?.StatusText === APPLICANT_REJECTED_STATUS.StatusText)) {
            setIsLogged(false);
            dispatch(Actions.loginReset());
            Logout(setCookie, removeCookie);
            navigate("/no-access", { replace: true });
        } else if (loginVal != null && loginVal?.data?.user_email) {

            let expiryTime = add(new Date(), { days: contentExpiry });

            if (rememberMe !== "") {
                expiryTime = add(new Date(), { days: remembermeExpiry });
                setCookie('dma-remember-me', '1', { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
            } else {
                removeCookie("dma-remember-me", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
            }
            
            setIsLogged(true);
            setCookie('front-end-app', '1', { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });

            setCookie('wp-login-user-token', loginVal?.data?.user_email, { expires: expiryTime, path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
            setCookie('HasTravelItinerary', loginVal?.data?.HasTravelItinerary, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
            setCookie('ShowDonorUpdateLink', loginVal?.data?.ShowDonorUpdateLink, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });

            localStorage.setItem("token", loginVal?.data?.token);

            removeCookie("front-end-logout", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
            navigate("/welcome", { replace: true });
        }

        dispatch(Actions.rejectStatusClear());
        // eslint-disable-next-line
    }, [rejectedResponse]);

    const checkRejected = (id) => {
        const endPoint = Endpoints.PROGRESS_BAR_DATA + id;
        let params = {
            endpoint: endPoint,
            method: "GET",
        };
        dispatch(Actions.rejectStatusRequest(params));
        return false;
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        setElementValidation("");
        let email = emailInputRef.current.value;
        let pwd = passwordInputRef.current.value;
        
        if (form.checkValidity() === false) {
            if (emailInputRef.current.validity.valueMissing === true || passwordInputRef.current.validity.valueMissing === true) {
                setElementValidation("Please enter your email and password.");
            }
            if (emailInputRef.current.validity.typeMismatch === true) {
                setElementValidation("Please enter a valid email address.");
            }
            setValidated(true);
        } else {
            setEmailValidated(true);
            setPwdValidated(true);
            let user = { username: email, password: btoa(pwd) }; //Buffer.from(pwd,'base64')
            dispatch(Actions.loginUserRequest(user));
        }
        event.preventDefault();
        event.stopPropagation();
    }

    if (loading || rejectedLoading) {
        return <Loader />;
    }
    if (!isLogged && !loading && !rejectedLoading) {
        return (

                <AuthLayout>
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: .3, delay: .5 }}
                        className={'py-3 px-4 mt-4 ' + styles.loginContainerBG + ' email-' + emailValidated + ' pwd-' + pwdValidated}>
                        <div className={styles.loginValidateContainer}>
                            {state != null && state.pwdstatus === "pwdsuccess" &&
                                <Alert message="Password was changed successfully." />
                            }

                            {loginFailed !== "" &&
                                <Alert message={loginFailed} />
                            }

                            {elementValidation !== "" &&
                                <Alert message={elementValidation} />
                            }
                        </div>
                
                        <Form className="auth-form" noValidate validated={validated} onSubmit={handleSubmit}>
                            <Form.Group className='mb-3'>
                                <Form.Control
                                    type='email'
                                    placeholder='Email'
                                    name="username"
                                    {...!emailValidated && { 'isInvalid': 'true' }}
                                    required
                                    ref={emailInputRef}
                                    onChange={e => setUser({ ...user, username: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group className='mb-1'>
                                <Form.Control
                                    className="mb-1"
                                    type='password'
                                    placeholder='Password'
                                    name="password"
                                    {...!pwdValidated && { 'isInvalid': 'true' }}
                                    required
                                    ref={passwordInputRef}
                                    onChange={e => setUser({ ...user, password: e.target.value })}
                                />
                            </Form.Group>
                            <div className={'text-end ' + styles.forgetPwdLink}>
                                <Link to='forgot-password'>Forgot Password?</Link>
                            </div>
                            <div className={'text-center ' + styles.rememberLogin}>
                                <div>
                                    <Form.Check
                                        type='checkbox'
                                        id='loginremember'
                                        ref={rememberMeRef}
                                        label='Keep me logged in'
                                        onChange={e => setRememberMe(e.target.value)}
                                    />
                                </div>
                            </div>
                            <motion.div className="d-flex justify-content-end mt-3 auth-button"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: .3, delay: 1 }}
                            >
                                <Button type="submit" className="d-flex align-items-center">
                                    Log In
                                    <motion.label className="btn-icon"
                                        initial={{ opacity: 0, scale: 0 }}
                                        animate={{ opacity: 1, scale: 1 }}
                                        transition={{ duration: 0.3, delay: 1 }}
                                    >
                                        <BsChevronRight />
                                    </motion.label>
                                </Button>
                            </motion.div>
                        </Form>
                    </motion.div>
                </AuthLayout>
        )
    }
}

export default Login;
