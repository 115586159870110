import styles from "./VideoLibrary.module.scss";
import Layout from "../components/ui/Layout";
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useEffect, useState } from "react";
import Loader from "../components/ui/Loader";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "./state/videos/actions";
import { Endpoints } from "../core/networking";
import TaskDescription from "./components/TaskDescription";
import PageTitle from "./components/PageTitle";
import { useCookies } from 'react-cookie';
import { Scrollbars } from "react-custom-scrollbars-2";
import { createPortal } from "react-dom";
import { motion } from "framer-motion";

const InfoSessionVideo = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { state } = useLocation();
    const [cookies, setCookie, removeCookie] = useCookies(null);
    const [taskInfo, setTaskInfo] = useState({});

    const [scrollbarHeight, setScrollBarHeight] = useState(window.screen.availHeight);
    const {
        videoPageData: { completedInfoVideo },
    } = useSelector((state) => state);

    const { response: completeInfoVideo, isLoading: videoCompleteLoading } = completedInfoVideo || {};
    
    const [clickedButton, setClickedButton] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false);

    useEffect(() => {
        
        setShowNotifyModal(false);
    }, []);

    useEffect(() => {
        document.title = 'Complete Info Session';
        setClickedButton(false);
        if (location?.state?.taskInfo)
            setTaskInfo(location?.state?.taskInfo);

        if (cookies["visitedLMS_" + location?.state?.taskInfo?.ID] === "1") {
            setClickedButton(true);
        }

    }, [cookies, location?.state?.taskInfo]);

    const goTalentLMS = () => {
        setClickedButton(true); 
        // set cookie flag for the button click so on page load or on navigate hides button
        setCookie('visitedLMS_' + taskInfo?.ID ?? taskInfo?.AssociatedID, 1, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
    }

    const showInfoSessionModal = () => {
        setShowNotifyModal(true);
    }

    const setLMSCompleted = () => {
        // mark task completed.
        let tID = (taskInfo?.ID ?? taskInfo?.AssociatedID);
        if (!tID) {
            tID = location?.state?.notifyTaskID;
        } 
        if (tID) {
            const taskcompleteEndpoint =
                Endpoints.HOME_COMPLETED_DONOR + tID;
            let params = {
                endpoint: taskcompleteEndpoint,
                method: "PUT"
            };

            dispatch(Actions.infoVideoComplete(params));
        }        

    }

    useEffect(() => {
        if (completeInfoVideo !== undefined && completeInfoVideo !== null && completeInfoVideo) {
            removeCookie("visitedLMS_" + taskInfo?.ID, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
            dispatch(Actions.infoVideoClear());
            navigate(-1);
        }
    }, [completeInfoVideo]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        // set scroll window height
        const mainElement = document.getElementsByClassName("app");
        let headerElement = document.querySelector('.homePageTitle');
        let footerElement = document.querySelector('.footerElement');
        setScrollBarHeight(mainElement[0]?.offsetHeight - (headerElement.clientHeight + footerElement.clientHeight + 12));
        window.addEventListener("resize", function () {
            setScrollBarHeight(mainElement[0]?.offsetHeight - (headerElement.clientHeight + footerElement.clientHeight + 12));
        });
    }, [scrollbarHeight]);


    const gotToSubmitNotify = () => {
        setShowNotifyModal(false);
        setLMSCompleted();
    }

    return (
        <>
            {(videoCompleteLoading) && <Loader />}
            {
                showNotifyModal && createPortal(
                    <div className={`${styles.notifyModalContainer} position-absolute w-100 top-0 start-0 end-0 bottom-0`}>
                        <motion.div className={`${styles.notifyModal} position-absolute top-50`}
                            initial={{ opacity: 0, scale: 0.5, translateY: '-50%' }}
                            animate={{ opacity: 1, scale: 1, translateY: '-50%' }}
                            transition={{ duration: 0.3 }}
                        >
                            <div className={styles.notifyModal} >
                                <div className={styles.modalHeader}>
                                    Info Session Task
                                </div>
                                <div className={styles.modalDetails}>
                                    Selecting this button confirms that you have watched the informational session. Only continue if you have watched the video. If you have not watched the video, click Go To Talent LMS and complete the training.
                                </div>
                                <div className={styles.modalFooter}>
                                    <input type="button" onClick={() => gotToSubmitNotify()} className={styles.footerbutton} value="Continue" />
                                     <span>
                                        <Link onClick={() => { goTalentLMS(); setShowNotifyModal(false); } } to='https://eggbank.talentlms.com/' target={"_blank"} className={`${styles.footerbutton}`} >Go To Talent LMS</Link>
                                    </span>
                                </div>
                            </div>
                        </motion.div>
                    </div>,
                    document.querySelector('.app')
                )
            }
            <Layout showHeader="false">
                <div className={styles.stickyHeader}>
                    <PageTitle
                        title="Complete Info Session"
                        headerClass="homePageTitle"
                        linkTo={state?.prevPage ? state?.prevPage : -1}
                        linkState={{ menu: state?.menu }}
                    />
                </div>
                <div className={`position-relative ${styles.videoContainer}`} id="videoContainer">
                    <Scrollbars
                        autoHeight
                        autoHeightMax={scrollbarHeight}
                        className={`${styles.infoSessionContainer} scroll-container`}
                    >
                        <TaskDescription styles={`px-3 py-0 my-2`} taskDetails={taskInfo} />
                        <div className={styles.infoSessionHeader}>
                            Watch the Information Session Video
                            <p>
                                The Info Session is an educational online video where you will learn about the entire process including who Fairfax EggBank is, the screening requirements, what a donation cycle looks like, risks and side effects. This video is about 30 minutes long and has knowledge checks along the way. You can stop the video at any point and return if needed, you will not lose your place. 
                            </p>
                            <p>
                                Click on the button below to access our Info Session.
                            </p>
                            <p><strong>Username:</strong> your email (without the @xxx.com)
                                <br />
                                <strong>Password:</strong> Eggdonor1
                                <br />
                                <strong>Domain name:</strong> eggbank
                            </p>
                            <p>**The username and password expires 1 week after the initial creation**</p>
                            <p>
                                Log into your account and watch the entire video. Once you complete the Info Session please return to the Donor Portal, click on the &ldquo;<strong>Complete Info Session</strong>&rdquo; Task, select yes, and submit the task to notify your Donor Coordinator that you have completed the task.
                            </p>
                            {
                                (!clickedButton && !showNotifyModal) &&
                                <div className={styles.infoSessionButton}>
                                        <Link onClick={() => goTalentLMS()} to='https://eggbank.talentlms.com/' target={"_blank"} className={`${styles.roundedBackgroundButton}`} >Complete the Info Session</Link>
                                </div>
                            }
                            <p>
                                If you have any questions, please reach out to dmasupport@fairfaxeggbank.com.
                            </p>
                            {
                                clickedButton &&
                                <div style={{margin:"40px 0"} }>
                                        Have you completed your  info session training in Talent LMS?
                                        <div className={styles.confirmTalentSection}>
                                            <span>
                                                <Link onClick={() => showInfoSessionModal()} className={`${styles.roundedBackgroundButton}`} >Yes</Link>
                                            </span>
                                            <span>
                                                <Link onClick={() => goTalentLMS()} to='https://eggbank.talentlms.com/' target={"_blank"} className={`${styles.roundedBackgroundButton}`} >No</Link>
                                            </span>
                                        </div>
                                </div>
                            }
                        </div>
                    </Scrollbars>
                </div>
            </Layout>
        </>
    );
}

export default InfoSessionVideo;
